import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  // set access token
  SessionService_SetAccessToken(oData: any) {
    localStorage.setItem(environment.AccessToken, JSON.stringify(oData))
  }
  // get access token
  SessionService_GetAccessToken() {
    return JSON.parse(localStorage.getItem(environment.AccessToken))
  }

  // set user details
  SessionService_SetUserDetails(oData: any) {
    localStorage.setItem('userDetails', btoa(JSON.stringify(oData)));
  }

  // get user details
  SessionService_GetUserDetails() {
    const encodedData = localStorage.getItem('userDetails');
    if (encodedData) return JSON.parse(atob(encodedData));
  }

  //***********Storing and getting Current Page  */
  SessionService_SetCurrentPage(oData: any) {
    localStorage.setItem('currentPage', JSON.stringify(oData))
  }
  SessionService_GetCurrentPage() {
    return JSON.parse(localStorage.getItem('currentPage'));
  }

  //***********Storing and getting Current Page  */
  SessionService_SetPageSize(oData: any) {
    localStorage.setItem('pageSize', JSON.stringify(oData))
  }
  SessionService_GetPageSize() {
    return JSON.parse(localStorage.getItem('pageSize'));
  }
}

