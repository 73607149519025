import { Component } from '@angular/core';
interface SideNavToggle{
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent  {
  isSideNavCollapsed = true;
  screenWidth: number = 0;

  constructor(){}
  onToggleSidenav(data: SideNavToggle):void
  {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }
}

