import { Component, Inject, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from './shared/session.service';
import { DataService } from './shared/data.service';
import { DOCUMENT } from '@angular/common';
import { UiService } from './shared/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Trillo DocAI.';
  constructor(private sessionService: SessionService, private renderer: Renderer2, private oDataService: DataService,@Inject(DOCUMENT) private document: Document,private sUIService : UiService) {
    let userRole = this.sessionService.SessionService_GetUserDetails();

    if (environment.Theme == 'kidm' && userRole?.role !== 'user') {
      this.renderer.addClass(document.body, environment.Theme)
    }

    this.oDataService.getUserDetails.subscribe(res => {
      if (res == 'user' && environment.Theme !== 'kidm') {
        this.renderer.addClass(document.body, 'user')
        this.renderer.removeClass(document.body, environment.Theme)

      }
      else if (res == 'user' && environment.Theme == 'kidm') {
        this.renderer.addClass(document.body, 'kidm-user');
        this.renderer.removeClass(document.body, environment.Theme);
      }

      else {
        this.renderer.removeClass(document.body, 'user')
        this.renderer.removeClass(document.body, 'kidm-user')
        this.renderer.addClass(document.body, environment.Theme)

      }
    })


    if (userRole?.role == 'user' && environment.Theme == 'kidm') {
      this.renderer.addClass(document.body, 'kidm-user');
      this.renderer.addClass(document.body, environment.Theme)
    }
    if (userRole?.role == 'user' && environment.Theme !== 'kidm') {
      this.renderer.addClass(document.body, userRole?.role)
      this.renderer.removeClass(document.body, environment.Theme)

    }
    let favcon = this.document.getElementById('favicon') as HTMLLinkElement;
    favcon.href = environment.Theme == 'kidm'?'../assets/images/favicon/kidm.png':'../assets/images/favicon/trillo.png';

  }
  ngOnInit(): void {
    let title = document.getElementById('app-title');
    title.textContent = environment.APP_NAME
    const currentURL = this.document.location.origin;
    if (currentURL){
      this.sUIService.updateTitle(environment.Theme == 'kidm' ? 'ICM' : 'Trillo DocAI');

    }
  }
}
