<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>

<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid px-3">
    <div class="d-flex justify-content-start logo-text">
      <div class="logo-text" >
        <img class="" [src]="environment.Theme === 'kidm' ? '../../../../assets/images/kidm-logo.png' : './../../../assets/images/logo-text-dark.png'" height="40" alt="" />
      </div>
          <!-- <span class="font-20 ms-3 ">Trillo Doc AI</span> -->
    </div>
    <div class="d-flex  justify-content-end" role="search">
      <div ngbDropdown class="d-inline-block me-3">
        <div class="profile-dropdown" ngbDropdownToggle>
          <i class="fa-light fa-user header-user-icon"></i>
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="header-user-role border-bottom">
            <button
              class="d-flex align-items-center align-item-center no-hover py-2 cursor-none"
              ngbDropdownItem
            >
              <div class="">
                <div class="profile-img position-relative">
                  <div class="profile-dropdown ">
                    <span>{{
                      userDetails?.fullName
                      ? userDetails?.fullName[0]
                      : userDetails?.userId[0]
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="ms-3 ">
                <span>
                  {{
                    userDetails?.firstName &&
                    userDetails?.lastName &&
                    userDetails?.firstName.length +
                      userDetails?.lastName.length <=
                      15
                      ? userDetails?.firstName + " " + userDetails?.lastName
                      : (userDetails.userId | titlecase)
                  }}
                </span>
                <p class="mb-0 font-12 text-muted">
                  {{ userDetails?.role | titlecase }}
                </p>
              </div>
            </button>
          </div>

      <div class="border-bottom py-1">
            <button
              class="pl-35 mb-2"
              ngbDropdownItem
              (click)="openChangePasswordModal(changePassword)"
            >
              <i class="fa-solid fa-key profile-dropdown-icons"></i>
              Change Password
            </button>
            <button
              class="pl-35 d-flex"
              ngbDropdownItem
              (click)="openUpdateProfileModal(updateProfile)"
            >
            <i class="fa-regular fa-user"></i>
              Update Profile
            </button>
          </div> 
          <button class="pl-35 pt-2 " ngbDropdownItem (click)="logout()">
            <i
              class="fa-solid mb-2 fa-arrow-right-from-bracket profile-dropdown-icons"
            ></i>
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #updateProfile let-modal>
  <app-loader *ngIf="loader"></app-loader>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Update Profile</h4>
  </div>
  <div class="modal-body">
    <div class="form-row">
    
      <form [formGroup]="updateProfileForm">
        <label class="form-label">First Name</label>
        <div class="input-group input-group-lg col-md-12">
          <input
            type="text"
            class="form-control"
            placeholder="First Name"
            formControlName="firstName"
          />
        </div>
        <div>
          <small
            *ngIf="updateProfileForm.controls['firstName'].hasError('pattern')"
            class="text-danger"
            >First character cannot be space
          </small>
          <small
            *ngIf="
              (!updateProfileForm.controls['firstName'].hasError('required') ||
                updateProfileForm.controls['firstName'].touched) &&
              !updateProfileForm.controls['firstName'].hasError('pattern') &&
              (updateProfileForm.get('firstName')?.value?.length <= 1 ||
                updateProfileForm.get('firstName')?.value?.length >= 33)
            "
            class="text-danger font-14"
          >
            First Name must be between 2-32 characters.</small
          >
        </div>
        <label class="form-label mt-3">Last Name</label>
        <div class="input-group input-group-lg col-md-12">
          <input
            type="text"
            class="form-control"
            placeholder="Last Name"
            formControlName="lastName"
          />
        </div>
        <div>
          <small
            *ngIf="updateProfileForm.controls['lastName'].hasError('pattern')"
            class="text-danger"
            >First character cannot be space
          </small>
          <small
            *ngIf="
              (!updateProfileForm.controls['lastName'].hasError('required') ||
                updateProfileForm.controls['lastName'].touched) &&
              !updateProfileForm.controls['lastName'].hasError('pattern') &&
              (updateProfileForm.get('lastName')?.value?.length <= 1 ||
                updateProfileForm.get('lastName')?.value?.length >= 33)
            "
            class="text-danger font-14"
          >
            Last Name must be between 2-32 characters.</small
          >
        </div>
      </form>
    </div>
  </div>

  <div class="mb-3 px-3">
    <div class="d-flex justify-content-end align-items-center">
      <button
      type="button"
      class="btn btn-outline-secondary me-3"
      (click)="dismissModal()"
      >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="submitProfileSettingData()"
    >
      Submit
    </button>
    </div>
  </div>
</ng-template>

<ng-template #changePassword let-modal>
  <app-loader *ngIf="loader"></app-loader>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Change Password</h4>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <label class="form-label" for="Userid">User Id</label>
      <form [formGroup]="resetPasswordForm">
        <div class="input-group input-group-lg col-md-12 mb-3">
          <input
            type="text"
            class="form-control readOnly"
            id="Userid"
            placeholder="User Id"
            formControlName="userId"
            readonly
          />
        </div>
        <label class="form-label" for="Currentpassword">Current password</label>
        <div class="input-group input-group-lg col-md-12">
          <input
            type="password"
            class="form-control"
            id="Currentpassword"
            placeholder="Current password"
            formControlName="oldPassword"
          />
        </div>
        <div class="mb-3">
          <small
            *ngIf="
              resetPasswordForm.controls['oldPassword'].hasError('required') &&
              resetPasswordForm.controls['oldPassword'].touched
            "
            class="text-danger"
            >Current password is required</small
          >
        </div>
        <label class="form-label" for="Newpassword">New Password</label>
        <div class="input-group input-group-lg col-md-12">
          <input
            type="password"
            class="form-control"
            id="Newpassword"
            placeholder="New Password"
            formControlName="password"
          />
        </div>
        <div class="mb-3">
          <small
            *ngIf="
              resetPasswordForm.controls['password'].hasError('required') &&
              resetPasswordForm.controls['password'].touched
            "
            class="text-danger"
            >New password is required</small
          >
          <small
            *ngIf="
              resetPasswordForm.controls['password'].hasError('pattern') &&
              resetPasswordForm.controls['password'].touched
            "
            class="text-danger"
            >Password needs to be at least 8 characters long.</small
          >
          <small
            *ngIf="
              resetPasswordForm.get('password').hasError('maxlength') &&
              resetPasswordForm.get('password').touched
            "
            class="text-danger"
          >
            Password cannot exceed 24 characters.</small
          >
        </div>
        <label class="form-label" for="Repeatpassword"
          >Confirm New Password</label
        >
        <div class="input-group input-group-lg col-md-12">
          <input
            type="password"
            class="form-control"
            id="Repeatpassword"
            placeholder="Confirm New Password"
            formControlName="rptPassword"
          />
        </div>
        <div class="mb-3">
          <small
            *ngIf="
              resetPasswordForm.controls['rptPassword'].hasError('required') &&
              resetPasswordForm.controls['rptPassword'].touched
            "
            class="text-danger"
            >Confirm new password is required</small
          >
          <small
            *ngIf="resetPasswordForm.controls['rptPassword'].errors?.['mustMatch'] && resetPasswordForm.controls['rptPassword'].dirty"
            class="text-danger"
            >Password and confirm new password not matched</small
          >
        </div>
      </form>
    </div>
  </div>

  <div class="mb-3 px-3">
    <div class="d-flex justify-content-end align-items-center">
      <button
      type="button"
      class="btn btn-outline-secondary me-3"
      (click)="dismissModal()"
      >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="resetPassword()"
    >
      Submit
    </button>
    </div>
  </div>
</ng-template>
