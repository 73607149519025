
const env = window["env"] || {};

export const environment = {
  production: env["production"] || false,
  BaseURL: env["BaseURL"] || 'https://api.eng-dev-2-402218.trilloapps.com',
  AccessToken : env["AccessToken"] || 'lsTrilloDocAIAccessToken',
  Server: env["Server"] || 'staging',
  APP_NAME: env["APP_NAME"] || 'Trillo DocAI',
  Title: env["Title"] || 'Trillo DocAI',
  branch: env["branch"] || 'development/1.0', 
  ImagesURL:env["ImagesURL"] || "",
  Theme: env["Theme"] || "trillo",
  OrgName: "cloud",
};
