import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '../session.service';
import { DataService } from '../data.service';
import { MustMatch } from '../must-match.validator';
import { environment } from 'src/environments/environment';

interface SideNavToggle {
  collapsed: boolean;
  screenWidth: number;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {
  imageUrl: string;
  userDetails: any;
  @Input() toggleSidebar: any;
  screenWidth: number = 0;
  collapsed: boolean = false;
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  resetPasswordForm: FormGroup;
  updateProfileForm: FormGroup;
  loader : boolean = false;
  displayErrorBlock: boolean = false;
  resMessage: { message: any; responseType: any; color: any; };
  displayNotification: boolean = false;
  searchString : string = "";
  currentUrl: string;
  environment:any = environment
  constructor(private route: Router, private sessionService: SessionService, private modalService: NgbModal, private formBuilder: FormBuilder, private dataService: DataService,private activatedRoute : ActivatedRoute) {
    this.userDetails = this.sessionService.SessionService_GetUserDetails()
    this.updateProfileFormControls()
    
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth
    if (this.screenWidth <= 992) {
      this.collapsed = false;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
    if(this.screenWidth >= 992){
      this.collapsed = true;
      this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth })
    }
  }
  toggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({ collapsed: this.collapsed, screenWidth: this.screenWidth });
  }

loadAvatar(){
    this.imageUrl = '../../../../assets/images/profile.jpeg';
}
logout() {
  localStorage.clear();
  this.route.navigateByUrl('/auth/login');
}
resetPasswordFormControls() {
  const passwordRejex = /^.{8,}$/;
  const maxPasswordLength = 24;
  this.resetPasswordForm = this.formBuilder.group({
    userId: [this.userDetails.userId],
    emailVerifyKey: [''],
    oldPassword: ['', Validators.required],
    password: ['', [ Validators.required, Validators.pattern(passwordRejex), Validators.maxLength(maxPasswordLength) ]],
    rptPassword: ['', Validators.required],
    orgName: ['']
  },
  { validator: MustMatch('password', 'rptPassword') });
}

updateProfileFormControls() {
  let textRejex = /^(?! )(.*)$/;
  this.updateProfileForm = this.formBuilder.group({
    id: [this.userDetails.id, [Validators.required]],
    firstName: [this.userDetails.firstName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]],
    lastName: [this.userDetails.lastName, [Validators.required, Validators.pattern(textRejex), Validators.minLength(2), Validators.maxLength(32)]]
  })
  
}

resetPassword() {
  if (this.resetPasswordForm.valid) {
    this.loader = true;
    let body = this.userDetails
    body.oldPassword = this.resetPasswordForm.controls['oldPassword'].value
    body.password = this.resetPasswordForm.controls['password'].value
    body.rptPassword = this.resetPasswordForm.controls['rptPassword'].value
    this.dataService.ChangePassword(body).subscribe({
      next: (result) => {
        if (result.failed) {
          this.loader = false;
          this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
        } else {
          this.modalService.dismissAll();
          this.resetPasswordForm.reset();
          this.loader = false;
          this.getProfileSettingDataToPatch()
          this.displayAlertMessage('Success', 'success', 'success');
        }
      },
      error: (error) => {
        this.displayAlertMessage('Current Password is Wrong!', 'error', 'danger');
        this.loader = false;
      },
      complete: () => { },
    })
  } else {
    this.resetPasswordForm.markAllAsTouched()
  }
}

getProfileSettingDataToPatch(){    
  this.dataService.GetUserProfile({userId:this.userDetails.id}).subscribe({
    next: (result) => {
      if (result.status=='failed') {
      } else {
        this.userDetails = this.sessionService.SessionService_GetUserDetails();
        this.imageUrl=this.userDetails?.pictureUrl??result.data.pictureUrl ;
        this.userDetails.pictureUrl = this.imageUrl;                  
        this.sessionService.SessionService_SetUserDetails(this.userDetails);
      }
    },
    error: (error) => { }
  })
}

submitProfileSettingData() {
  if(this.updateProfileForm.valid){
    this.userDetails.firstName=this.updateProfileForm.value.firstName;
    this.userDetails.lastName=this.updateProfileForm.value.lastName;
          this.loader = true;   
      this.dataService.SetUserProfile(this.userDetails).subscribe({
        next: (result) => {
          if (result.status === 'failed') {
            this.loader = false;
            this.displayAlertMessage(result.message,'danger','danger')
          } else {
            this.displayAlertMessage(result.message,'success', 'success');          
            this.sessionService.SessionService_SetUserDetails(this.userDetails); 
            this.modalService.dismissAll();
            this.loader = false;
          }
        },
        error: (error) => {
          this.loader = false;
          console.error("SubmitProfileSettingData: ERROR ===>>", error);
          this.displayAlertMessage(error.message,'danger','danger')
        },
      });
  } else {
    this.updateProfileForm.markAllAsTouched();
  }
}
dismissModal() {
  this.modalService.dismissAll();
  this.updateProfileForm?.reset();
  this.resetPasswordForm?.reset();
  this.updateProfileFormControls();
  this.resetPasswordFormControls();
}

openChangePasswordModal(changePassword) {
  this.modalService.open(changePassword, { centered: true, size: 'md' });
  this.resetPasswordFormControls()
}

openUpdateProfileModal(updateProfile) {
  this.modalService.open(updateProfile, { centered: true, size: 'md' });
}

displayNotifications() {
  setTimeout(() => {
    this.displayNotification = !this.displayNotification
  }, 100);
}

clickOutside() {
  this.displayNotification = false;
}


ngOnDestroy(): void {
  this.modalService.dismissAll()
}

  // ************************** ALERT MESSAGES ***************************/

displayAlertMessage(incomingMessage, incomingResponseType, incomingColor) {
  this.displayErrorBlock = true
  this.resMessage = {
    message: incomingMessage,
    responseType: incomingResponseType,
    color: incomingColor
  };
  setTimeout(() => { this.displayErrorBlock = false; }, 3000);
}

}
