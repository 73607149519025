export const navbarData = [
    {
        routerLink: '/doc-ai',
        icon:'fa-home',
        label: 'Dashboard',
    },
    {
        routerLink: '/doc-ai/text-search',
        icon:'fa-print-magnifying-glass',
        label: 'Text Search',
    },
    {
        routerLink: '/doc-ai/semantic-matching',
        icon:'fa-file-pen',
        label: 'Semantic Matching'
    },
    {
        routerLink: '/doc-ai/data-search',
        icon:'fa-solid fa-book',
        label: 'Data Search'
    },
    
]