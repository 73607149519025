import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private http: HttpClient) {}
  getUserDetails = new BehaviorSubject<any>("");

  // ***** Get Global Search Results For Document Manager ***** //
  DataService_GetGlobalSearchResultsForDocumentManager(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + "/docsvc/search2", body);
  }
  DataService_GetSearchHistory(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/api/v1.1/docsvc/folder/conversations/" + body
    );
  }
  DataService_GetConversations(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/api/v1.1/docsvc/conversation/" + body
    );
  }
  // Extract Data From Global Search Files
  DataService_ExtractDataFromGlobalSearchFiles(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/docsvc/docAIResult?docId=" + body
    );
  }

  forgotPassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_preauthsvc/user/forgotPassword",
      body
    );
  }

  //************* User Manager API starts */
  GetUsersList(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/page/auth/vault",
      body
    );
  }
  CreateUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/newUser",
      body
    );
  }

  UploadUsers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/authds/uploadUsers",
      body
    );
  }
  EditUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/editUser",
      body
    );
  }
  ResetPassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/resetPassword",
      body
    );
  }
  SuspendUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/toggleSuspendActive",
      body
    );
  }
  DeleteUser(id: any): Observable<any> {
    return this.http.delete<any>(
      environment.BaseURL + "/_service/um/deleteUser" + "?id=" + id
    );
  }
  AddNewTenant(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/save/auth/vault/Org",
      body
    );
  }
  //************* User Manager API Ends */

  //Setting ======>> User Profile API's End
  GetUserProfile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetUserProfile",
      body
    );
  }
  SetUserProfile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/editMyProfile",
      body
    );
  }
  ChangePassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/changeMyPassword",
      body
    );
  }

  DataService_DocRetrieveSignedUrl(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/folder/retrieveSignedUrl",
      body
    );
  }
}
