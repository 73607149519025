import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { canActivateTeam } from './guards/auth.guard';
import { UserRoleGuard } from './guards/user-role.guard';

const routes: Routes = [
  { path: "", redirectTo: "/auth/login", pathMatch: "full" },
  {
    path: "",
    component: BlankComponent,
    children: [
      { path: "", redirectTo: "/auth/login", pathMatch: "full" },
      {
        path: "auth",
        loadChildren: () =>
          import("../app/modules/authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "",
    component: FullComponent, canActivate: [canActivateTeam],
    children: [
      {
        path: 'doc-search',
        loadChildren: () => import('./modules/doc/doc.module').then(m => m.DocModule)
      },

      {
        path: "admin", canActivate: [UserRoleGuard],
        loadChildren: () =>
          import("./modules/admin/admin.module").then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "auth/login",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
