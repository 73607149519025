import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { LoaderComponent } from './loader/loader.component';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { HeadersInterceptor } from 'src/interceptors/headers.interceptor';



@NgModule({
  declarations: [
    HeaderComponent,
    AlertsComponent,
    LoaderComponent,
    CustomPaginationComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    RouterModule
  ],
  providers: [
    HeadersInterceptor
  ],
  exports:[ HeaderComponent,AlertsComponent,LoaderComponent,CustomPaginationComponent ]
})
export class SharedModule { }
